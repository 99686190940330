import React, { useEffect, useState } from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

export default function Image({
  src,
  alt = "image not found",
  basicClass,
  popupClass,
}) {
  const [popupMode, setPopupMode] = useState(false);
  const [activeImg, setActiveImg] = useState();
  const [imagesList, setImagesList] = useState();
  const [prevIsActive, setPrevIsActive] = useState(false);
  const [nextIsActive, setNextIsActive] = useState(false);

  const Img = ({ styleClass }) => (
    <img
      onClick={onClickImaage}
      src={src}
      alt={alt}
      className={`${styleClass} imgStyle`}
    />
  );

  const PopupImg = ({ styleClass }) => (
    <img
      src={activeImg.src}
      alt={alt}
      className={`${styleClass} imgStyle`}
      data-type="image"
    />
  );

  const onClickImaage = (e) => {
    const image = e.target;
    const imagesContainer = image
      .closest(".specialClassToSearchImages")
      .getElementsByTagName("img");

    const allImages = []

    for (const image of imagesContainer)
    allImages.push(image.src)

    const uniqImges = [...new Set(allImages)];

    const images = uniqImges.map((i, index) => ({
      id: index,
      src: i,
    }));

    setImagesList(images);
    setPopupMode(true);
  };

  const goToPrev = () => {
    setActiveImg(imagesList.filter((f) => f.id === activeImg.id - 1)[0]);
  };

  const goToNext = () => {
    setActiveImg(imagesList.filter((f) => f.id === activeImg.id + 1)[0]);
  };

  useEffect(() => {
    setActiveImg(
      imagesList?.filter(
        (f) => f.src.split("/").pop() === src.split("/").pop()
      )[0]
    );
  }, [imagesList]);

  useEffect(() => {
    setPrevIsActive(activeImg && activeImg.id !== 0);
    setNextIsActive(activeImg && activeImg.id !== imagesList.length - 1);
  }, [activeImg]);

  const onBackgroundClick = (e) => {
    let target = "";
    if (e.target.tagName === "path") {
      target = e.target.parentNode.dataset.type;
    } else {
      target = e.target.dataset.type;
    }
    if (!target) {
      setPopupMode(false);
    } else if (target === "background") {
      setPopupMode(false);
    } else if (target === "navigation-prev") {
      if (prevIsActive) {
        goToPrev();
      } else {
        setPopupMode(false);
      }
    } else if (target === "navigation-next") {
      if (nextIsActive) {
        goToNext();
      } else {
        setPopupMode(false);
      }
    } else return false;
  };

  return (
    <>
      <Img styleClass={basicClass} />

      {popupMode && (
        <div
          className="absolute z-20 backdrop-blur-sm backdrop-brightness-75 w-full h-full inset-0 cursor-pointer flex items-center justify-center outline-none"
          onClick={onBackgroundClick}
          data-type="background"
        >
          <div className="flex sm:flex-row flex-col items-center items-stretch p-4">
            <div
              className={`${
                prevIsActive ? "hover:bg-white/50" : ""
              } p-4 sm:bg-transparent flex items-center justify-center duration-300`}
              data-type="navigation-prev"
            >
              <MdOutlineArrowBackIos
                className={`${prevIsActive ? "" : "text-transparent"} text-5xl`}
                data-type="navigation-prev"
              />
            </div>
            {activeImg && (
              <PopupImg styleClass={`imageMaxHeight outline-none ${popupClass}`} />
            )}
            <div
              className={`${
                nextIsActive ? "hover:bg-white/50" : ""
              } p-4 sm:bg-transparent flex items-center justify-center duration-300`}
              data-type="navigation-next"
            >
              <MdOutlineArrowForwardIos
                className={`${nextIsActive ? "" : "text-transparent"} text-5xl`}
                data-type="navigation-next"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
