export function getPosts() {
  return fetch("data/posts.json")
    .then((resp) => resp.json())
    .then((resp) => resp.posts.reverse());
}

export function getPhotos() {
  return fetch("data/gallery.json")
    .then((resp) => resp.json())
    .then((resp) => resp.gallery.reverse());
}
