import React from "react";

import Image from "../components/Image";

export default function Post({ post }) {
  const { id, title, createDate, content, images } = post;

  return (
    <div className="flex flex-col bg-slate-200 dark:bg-slate-800 last:mb-0 mb-8 shadow-xl text-slate-700 dark:text-slate-200 duration-300 rounded-xl">
      <div className="p-4">
        <div className="flex sm:flex-row flex-col sm:justify-between">
          <h3 className="gradientText mb-4 w-full">{title}</h3>
          <h4 className="font-bold text-xs mb-4 sm:pt-2 sm:pl-4">
            {createDate}
          </h4>
        </div>
        <p>{content.replaceAll(":)", "🙂").replaceAll(":(", "😔")}</p>
        {images?.length ? (
          <div className="flex flex-wrap items-start specialClassToSearchImages">
            {images.map((imageId) => (
              <Image
                key={`${id}-${imageId}`}
                src={`images/posts/bartek-${id}-${imageId}.jpeg`}
                basicClass="postImageMaxWidthMobile sm:postImageMaxWidth mt-4 mr-4 rounded-lg"
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
