import React from "react";
import { useQuery } from "@tanstack/react-query";

import { getPhotos } from "../api";

import Loader from "../components/Loader";
import Photo from "../components/Photo";

export default function Gallery() {
  const {
    data: photos,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["photos"],
    queryFn: getPhotos,
  });

  return (
    <div>
      <h1>Galeria</h1>

      {isLoading && <Loader />}

      {isError && (
        <p className="text-red-500">
          Nie udało się pobrać zdjęć. Błąd: {error.message}
        </p>
      )}

      <div
        className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 specialClassToSearchImages"
      >
        {photos &&
          photos.map((photo) => <Photo key={photo.id} photo={photo} />)}
      </div>
    </div>
  );
}
