import React from "react";

import bartek from "../images/bartek.jpg";

export default function About() {
  return (
    <div>
      <h1>O Bartku</h1>

      <h3 className="pb-8 text-slate-700 dark:text-slate-200 duration-300">
        Nigdy nie myśleliśmy że przyjdzie nam doświadczyć tyle troski i zmagań o
        każdy oddech naszego dziecka
      </h3>

      <img src={bartek} className="mb-8 shadow-xl rounded-xl" />

      <div className="text-slate-700 dark:text-slate-200 duration-300 bg-slate-200 dark:bg-slate-800 p-4 rounded-xl shadow-xl mb-8">
        <h3 className="pb-4 gradientText">Bartek</h3>
        <p>
          Bartuś urodził się 29 października 2019 roku, jako czwarte nasze
          dziecko. Ma starszą o pięć lat siostrę Agnieszkę, trzy i pół roku
          starszego brata Mikołaja i półtora roku starszego brata Kubusia.
        </p>
        <p>
          Bartuś od pierwszych chwil życia miał trudności z oddychaniem, dlatego
          od razu po porodzie został zabrany na Oddział Patologii Noworodka.
          Nasz syn wymagał wsparcia oddechowego, początkowo przy pomocy CPAP'u,
          później respiratora. Nikt się nie spodziewał, że respirator zostanie z
          nim na całe życie. A już na pewno nie spodziewaliśmy się tego my –
          rodzice, jak dotąd zdrowych dzieci.
        </p>
        <br></br>
        <p>
          Po pierwszym szoku i niedowierzaniu temu co się dzieje przyszedł czas
          cierpliwego czekania na kolejne wyniki… I tak, po długich dwóch
          miesiącach niepewności co będzie usłyszeliśmy to co nie chcieliśmy
          usłyszeć – trudną diagnozę że nasz syn nigdy nie będzie oddychał
          samodzielnie.
        </p>
        <p>
          Bartuś ma Zespół Wrodzonej Ośrodkowej Hipowentylacji (CCHS) –
          potocznie choroba ta nazywana jest Klątwą Ondyny.
        </p>
      </div>

      <div className="text-slate-700 dark:text-slate-200 duration-300 bg-slate-200 dark:bg-slate-800 p-4 rounded-xl shadow-xl mb-8">
        <h3 className="pb-4 gradientText">Łapie</h3>
        <p>No właśnie &ldquo;łapie&rdquo; nie tylko oddech...</p>
        <br></br>
        <p>
          &ldquo;Złapał&rdquo; – Klątwę Ondyny – a jest to rzadka choroba
          genetyczna, z którą w Polsce żyje tylko około 30 osób. U Bartusia
          doszło do mutacji genu PHOX2B 26/20. Choroba ta objawia się tym, że
          gdy dziecko zasypia, przestaje oddychać. Musi wtedy być podpięte do
          respiratora i stale monitorowane.
        </p>
        <p>
          Po diagnozie i potwierdzeniu CCHS, Bartuś przeszedł zabieg
          tracheotomii i poprzez rurkę włożoną do tchawicy jest teraz
          wentylowany za pomocą respiratora. Respirator to tylko jedna z maszyn
          bez których Bartuś nie jest w stanie funkcjonować. Jest jeszcze
          pulsoksymetr, ssak, koncentrator tlenu. Do wspomagania monitoringu
          przydaje się jeszcze kapnometr. Bartuś wymaga opieki przez całą dobę i
          ciągłego monitorowania parametrów życiowych.
        </p>
        <p>
          Klątwie Ondyny często towarzyszy choroba Hirschsprunga – i niestety
          Bartuś &ldquo;złapał&rdquo; też i to. Choroba ta polega na braku
          unerwienia części jelita grubego. Bartuś już jest po pierwszej
          operacji związanej z wyłonieniem stomii (sztucznego odbytu) w
          okolicach pępka, a za kilka miesięcy czeka go druga – nazywana
          radykalną - która polega na wycięciu niedrożnego odcinka, połączeniu
          zdrowego jelita z odbytem i usunięciu stomii. Z niecierpliwością
          czekamy na tą operację, bo bardzo trudno patrzeć na &ldquo;to
          coś&rdquo; w brzuszku własnego dziecka.
        </p>
        <p>
          Wentylacja mechaniczna, wkłucia centralne oraz ciągłe przebywanie na
          oddziałach szpitalnych narażają Bartusia na niebezpieczeństwo
          infekcji, które stanowią dla niego poważne zagrożenie zdrowia i życia.
        </p>
        <p>
          Bartuś ma za sobą trzy wizyty na bloku operacyjnym, kilkukrotne
          zapalenie płuc i trudne infekcje wywołane groźnymi bakteriami.
        </p>
        <br></br>
        <p>
          Diagnoza Bartusia nie jest jeszcze skończona. Jest kilka podejrzeń co
          jeszcze &ldquo;złapał&rdquo; jak mukowiscydoza, miopatia mięśniowa,
          polimikrogyria czy drgawki wskazujące na padaczkę.
        </p>
        <p>
          Dużo jak na jednego małego człowieka ale pomimo tego Bartuś się nie
          poddaje.
        </p>
      </div>
      <div className="text-slate-700 dark:text-slate-200 duration-300 bg-slate-200 dark:bg-slate-800 p-4 rounded-xl shadow-xl">
        <h3 className="pb-4 gradientText ">Oddech</h3>
        <p>Ach ten Oddech...</p>
        <br></br>
        <p>No właśnie.</p>
        <br></br>
        <p>Oddech – choć wspomagany pozwala Bartusiowi żyć!</p>
        <p>
          I choć, jak dzielą się starsi koledzy i koleżanki z CCHS, początki są
          trudne to jednak dają oni nadzieję, że z każdym rokiem jest coraz
          lepiej, że da się rozwijać, mówić, chodzić do szkoły, pracować… Wymaga
          to wielu starań, ale jest to możliwe. I tego się trzymamy!
        </p>
        <p>
          I choć na razie ten mały człowiek ma wokół siebie pełno rurek i
          kabelków, a w jego małym ciele jest tracheostomia, cewnik Broviaca,
          stomia i sonda – to pod tym wszystkim kryje się dzielny, mały WIELKI
          WOJOWNIK, który walczy wytrwale, który patrzy swoimi wielkimi ciemnymi
          oczkami co się dzieje i pomimo cierpienia uśmiecha się i stroi minki
          jak zupełnie zdrowy chłopczyk. Jak gdyby nigdy nic go złego nie
          spotkało.
        </p>
      </div>
    </div>
  );
}
