import React from "react";

import { MdFacebook } from "react-icons/md";

export default function Footer() {
  return (
    <footer className="mt-10 bg-slate-300 dark:bg-slate-900 text-slate-600 dark:text-slate-400 duration-300 text-xs w-full">
      <div className="flex flex-col">
        <div className="h-8 bg-slate-200 dark:bg-slate-800 duration-300"></div>

        <div className="flex flex-col items-center py-12">
          <div className="max-w-screen-lg w-full justify-between flex flex-col sm:flex-row px-4">
            <div className="flex flex-col items-start">
              <span className="pb-1">Kontakt:</span>
              <span className="pb-1">Email: kontakt@barteklapieoddech.pl</span>
              <span className="pb-1">Telefon: 515 632 543 | Dorota</span>
              <a href="" className="pt-4">
                <MdFacebook className="text-3xl" />
              </a>
            </div>

            <div className="flex flex-col sm:items-end pt-4 sm:pt-0">
              <a
                className="pb-1 w-fit underline underline-offset-2"
                href="http://zdejmijklatwe.org/pl/"
                target="blank"
              >
                Polska Fundacja CCHS Zdejmij Klątwę
              </a>

              <a
                className="pb-1 w-fit underline underline-offset-2"
                href="https://ondinata.com/pl/"
                target="blank"
              >
                Pieśni dla Ondyny
              </a>

              <a
                className="pb-1 w-fit underline underline-offset-2"
                href="https://www.fundacja-sloneczko.pl/"
                target="blank"
              >
                Fundacja pomocy dziecią Słoneczko
              </a>
            </div>
          </div>
        </div>
        <div className="h-1.5 gradient"></div>
      </div>
    </footer>
  );
}
