import React from "react";

import Image from "../components/Image";

import ulotka1 from "../images/ulotka-1.jpg";
import ulotka2 from "../images/ulotka-2.jpg";
import ulotka from "../files/ulotka.pdf";

export default function Help() {
  return (
    <div>
      <h1>Wsparcie</h1>

      <h3 className="pb-8 text-slate-700 dark:text-slate-200 duration-300">
        Dziękujemy za wsparcie.<br />Zebrane środki są wykorzystywane na pokrycie bierzących potrzeb związanych głównie z rehabiltcją Bartka.<br/>Docelowo będą stanowić bazę do sfinansowania operacji wszczepienia stymulatora nerwu przepony.
      </h3>

      <div className="text-slate-700 dark:text-slate-200 mb-8 duration-300 bg-slate-200 dark:bg-slate-800 p-4 rounded-xl shadow-xl">
        <h3 className="gradientText mb-4">Możesz przekazać 1,5% podatku</h3>
        <p>KRS 0000186434</p>
        <p>Cel Szczegółowy: 450/C Bartłomiej Czernek</p>
      </div>

      <div className="text-slate-700 dark:text-slate-200 mb-8 duration-300 bg-slate-200 dark:bg-slate-800 p-4 rounded-xl shadow-xl">
        <h3 className="gradientText mb-4">
          Możesz przekazać darowiznę na konta fundacji
        </h3>
        <p className="mb-4">
          Bardzo ważne jest podanie poprawnego tytułu wpłaty
        </p>

        <p>Darowizny w PLN: 89 8944 0003 0000 2088 2000 0010</p>
        <p className="mb-4">W tytule wpisując: 450/C Bartłomiej Czernek</p>

        <p>Darowizny w EUR: 76 8944 0003 0000 2088 2000 0050</p>
        <p className="mb-4">W tytule wpisując: 450/C Bartłomiej Czernek</p>

        <p>Darowizny w USD: 97 8944 0003 0000 2088 2000 0060</p>
        <p className="mb-4">W tytule wpisując: 450/C Bartłomiej Czernek</p>

        <p>
          Dla wpłacających z zagranicy kod SWIFT (inaczej zwany BIC): GBW CPL PP
        </p>
        <p>Kod kraju: PL</p>
      </div>

      <div className="text-slate-700 dark:text-slate-200 duration-300 bg-slate-200 dark:bg-slate-800 p-4 rounded-xl shadow-xl">
        <h3 className="gradientText mb-4">Ulotka</h3>

        <p className="mb-4">
          Możesz pobrać ulotkę w formacie pdf. <a href={ulotka} className="gradientText" download="Bartek Łapie Oddech">Ulotka</a>
        </p>

        <div className="flex flex-wrap specialClassToSearchImages">
          <Image
            src={ulotka1}
            basicClass="postImageMaxWidthMobile sm:postImageMaxWidth mt-4 mr-4 rounded-lg"
          />

          <Image
            src={ulotka2}
            basicClass="postImageMaxWidthMobile sm:postImageMaxWidth mt-4 mr-4 rounded-lg"
          />
        </div>
      </div>
    </div>
  );
}
