import React from "react";

import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Loader() {

  return (
    <div className="flex justify-center w-full py-10 text-4xl text-slate-700 dark:text-slate-100 duration-300">
        <AiOutlineLoading3Quarters className="animate-spin"/>
    </div>
  );
}
