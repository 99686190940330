import React from "react";
import { useQuery } from "@tanstack/react-query";

import { getPosts } from "../api";

import Loader from "../components/Loader";
import Post from "../components/Post";

export default function News() {
  const {
    data: posts,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["posts"],
    queryFn: getPosts,
  });

  return (
    <div>
      <h1>Aktualności</h1>

      {isLoading && <Loader />}

      {isError && (
        <p className="text-red-500">
          Nie udało się pobrać aktualości. Błąd: {error.message}
        </p>
      )}

      {posts && posts.map((post) => <Post key={post.id} post={post} />)}
    </div>
  );
}
