import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

export default function Navigations({ setShowMenu = () => null }) {
  const navs = [
    { name: "O Bartku", path: "info", id: 1 },
    { name: "Aktualności", path: "aktualnosci", id: 2 },
    { name: "Wsparcie | 1,5%", path: "wsparcie", id: 3 },
    { name: "Galeria", path: "galeria", id: 4 },
  ];

  const Links = navs.map((nav) => (
    <NavigationLink key={nav.id} nav={nav} setShowMenu={setShowMenu} />
  ));

  return Links;
}

function NavigationLink({ nav, setShowMenu }) {
  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const { path, name } = nav;

  return (
    <li>
      <NavLink to={`/${path}`}>
        {({ isActive }) => (
          <div
            className={`p-4 font-semibold cursor-pointer hover:backdrop-brightness-125 ease-in duration-100 ${
              isActive ? "backdrop-brightness-75" : ""
            }`}
            onClick={handleCloseMenu}
          >
            {name}
          </div>
        )}
      </NavLink>
    </li>
  );
}

NavigationLink.propTypes = {
  nav: PropTypes.object,
  setShowMenu: PropTypes.func,
};
