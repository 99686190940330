import React from "react";

export default function Home() {
  return (
    <div className="flex flex-col text-slate-700 dark:text-slate-200 duration-300">
      <h1 className="sm:pt-8 text-4xl sm:text-6xl text-center self-center">
        Bartek łapie odddech
      </h1>
      <h2 className="pt-6">
        Oddech, taka prosta rzecz... wdech i wydech... i już... ale niestety,
        dla naszego syna nie jest to takie proste. Bartek nie oddycha
        samodzielnie - cierpi na rzadką, genetyczną chorobę, potocznie zwaną -
        Klątwą Ondyny
      </h2>
      <h3 className="pt-6">
        Dla nas, rodziców, najważniejszym celem jest zapewnienie Bartkowi jak
        najlepszych warunków do rozwoju aby pomimo choroby i swoich ograniczeń
        mógł dorównywać rówieśnikom. Aby osiągnąć ten cel Bartek potrzebuje
        wielu godzin rehabilitacji i wsparcia dużej ilości specjalistów.
        Wierzymy, że z Bożą pomocą i z pomocą ludzi o dobrych sercach, Bartek
        będzie mógł żyć jak jego rówieśnicy. Możesz pomóc Bartusiowi przekazując
        1,5% swojego podatku na jego leczenie i rehabilitację.
      </h3>
      <div className="items-start pt-6 gradientText">
        <h2>KRS 0000186434</h2>
        <h2>Cel Szczegółowy: 450/C Bartłomiej Czernek</h2>
      </div>
      <div className="items-start pt-6">
        <p>Z całego serca dziękujemy za każdą pomoc</p>
        <p>Dorota i Tomek</p>
      </div>
    </div>
  );
}
