import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { MdOutlineMenu, MdLightMode, MdDarkMode } from "react-icons/md";

import Navigations from "../components/Navigations";
import Footer from "../components/Footer";

export default function Layout() {
  const [showMenu, setShowMenu] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const toggleManu = () => {
    setShowMenu(!showMenu);
  };

  const toogleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const ThemeIcon = () => {
    return (
      <div onClick={toogleTheme}>
        {theme === "light" ? (
          <MdDarkMode className="sm:ml-4 text-2xl cursor-pointer mr-4 duration-300" />
        ) : (
          <MdLightMode className="sm:ml-4 text-2xl cursor-pointer mr-4 duration-300" />
        )}
      </div>
    );
  };

  return (
    <div className={theme}>
      <div className="bg-slate-100 dark:bg-slate-700 text-slate-100 duration-300 overScroll h-screen">
        <div className="gradient z-20 shadow-xl fixed w-full top-0">
          <div className="flex justify-between items-center">
            <Link
              className="p-4 font-semibold"
              to="/"
              onClick={() => setShowMenu(false)}
            >
              Bartek Łapie Oddech
            </Link>

            <div className="flex items-center">
              <nav className="hidden sm:block">
                <ul className="flex flex-row">
                  <Navigations />
                </ul>
              </nav>

              <MdOutlineMenu
                className="block sm:hidden text-2xl cursor-pointer mr-6"
                onClick={toggleManu}
              />

              <ThemeIcon />
            </div>
          </div>

          {showMenu && (
            <div className="sm:hidden gradient w-full drop-shadow-xl duration-300">
              <ul className="flex flex-col">
                <Navigations setShowMenu={setShowMenu} />
              </ul>
            </div>
          )}
        </div>

        <div
          onClick={toggleManu}
          className={`w-full z-10 backdrop-blur-sm backdrop-brightness-90 ${
            showMenu ? "h-full inset-0 fixed" : ""
          }`}
        ></div>

        <div className="flex flex-col justify-between items-center min-h-screen">
          <div className="p-4 flex flex-col max-w-screen-lg w-full">
            <Link
              className="p-4 font-semibold dark:text-slate-700 text-slate-100"
              to="/"
            >
              Bartek łapie oddech
            </Link>

            <div className="">
              <Outlet />
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
}
