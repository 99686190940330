import React from "react";

import Image from "./Image";

export default function Photo({ photo }) {
  const { id, content } = photo;

  return (
    <div className="bg-slate-200 dark:bg-slate-800 text-slate-700 dark:text-slate-200 duration-300 shadow-xl rounded-xl self-start">
      <Image
        src={`images/gallery/bartek-${id}.jpeg`}
        basicClass="rounded-tl-xl rounded-tr-xl"
      />
      <p className="p-4">{content}</p>
    </div>
  );
}
