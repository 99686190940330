import React from "react";
import { Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import Layout from "./views/Layout";
import About from "./views/About";
import Gallery from "./views/Gallery";
import News from "./views/News";
import Home from "./views/Home";
import Help from "./views/Help";

export default function App() {
  const oneOnHours = 1000 * 60 * 60;

  const queryClient = new QueryClient({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: oneOnHours,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="info" element={<About />} />
          <Route path="aktualnosci" element={<News />} />
          <Route path="wsparcie" element={<Help />} />
          <Route path="galeria" element={<Gallery />} />
        </Route>
      </Routes>
    </QueryClientProvider>
  );
}
